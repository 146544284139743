import React, { useState, useEffect, useRef, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import './CiscoIOSCLI.css';

const config = {
  animationDelay: 0 // Delay in milliseconds
};

const BrianBellCLI = () => {
  const [output, setOutput] = useState([]);
  const [input, setInput] = useState('');
  const [stage, setStage] = useState('initializing');
  const inputRef = useRef(null);
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const hasBootedRef = useRef(false);
  const [mode, setMode] = useState('user'); // 'user' or 'privileged'
  const [awaitingPassword, setAwaitingPassword] = useState(false);
  const outputRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [showVirtualReturn, setShowVirtualReturn] = useState(false);
  const containerRef = useRef(null);
  const [attemptCount, setAttemptCount] = useState(0);
  const [isAttemptingEnable, setIsAttemptingEnable] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(true);
  const idleTimerRef = useRef(null);

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  const addCharacter = useCallback((text) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setOutput(prev => {
          const lastLine = prev[prev.length - 1] || '';
          const newLastLine = lastLine + text;
          return [...prev.slice(0, -1), newLastLine];
        });
        resolve();
      }, config.animationDelay);
    });
  }, []);

  const addLine = useCallback(async (text, isPassword = false, isHTML = false) => {
    if (isPassword) {
      setOutput(prev => [...prev, text]);
    } else {
      setOutput(prev => [...prev, '']);
      if (isHTML) {
        setOutput(prev => [...prev.slice(0, -1), text]);
      } else {
        for (let char of text) {
          await addCharacter(char);
        }
        await addCharacter('\n');
      }
    }
  }, [addCharacter]);

  const simulateLoading = useCallback(async () => {
    const lines = Math.floor(Math.random() * (6 - 2 + 1)) + 2; // Random number between 2 and 6
    for (let i = 0; i < lines; i++) {
      await addLine("#".repeat(80));
    }
  }, [addLine]);

  const logout = useCallback(async () => {
    setIsLoggedOut(true);
    setMode('user');
    setOutput([]);
    await addLine('');
    await addLine('');
    await addLine('');
    await addLine('BRIAN-BELL con0 is now available');
    await addLine('');
    await addLine('');
    await addLine('');
    await addLine('Press RETURN to get started.');
    await addLine('');
    setStage('waitingForReturn');
  }, [addLine, setMode, setOutput, setStage]);

  const resetIdleTimer = useCallback(() => {
    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }

    const timeout = isFirstLogin ? 60000 : 600000; // 1 minute for first login, 10 minutes for subsequent logins

    idleTimerRef.current = setTimeout(() => {
      if (stage === 'ready' && !isLoggedOut) {
        logout();
      }
    }, timeout);
  }, [isFirstLogin, stage, isLoggedOut, logout]);

  useEffect(() => {
    return () => {
      if (idleTimerRef.current) {
        clearTimeout(idleTimerRef.current);
      }
    };
  }, []);

  const handleGlobalKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && stage === 'waitingForReturn') {
      e.preventDefault();
      setStage('ready');
      setIsLoggedOut(false);
      setOutput(prev => [...prev, '']);
      setIsFirstLogin(false);
      resetIdleTimer();
    }
  }, [stage, setStage, setIsLoggedOut, setOutput, resetIdleTimer]);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - 3) + '...';
  };

  useEffect(() => {
    const bootSequence = async () => {
      if (hasBootedRef.current) return; // Prevent multiple executions
      hasBootedRef.current = true;

      await addLine("Brian Bell Portfolio Boot Loader (BB-BOOT-M) Version 1.0, RELEASE SOFTWARE (fc1)");
      await addLine("Brian Bell Portfolio (RC32300) processor (revision C0) with 21039K bytes of memory.");
      await addLine("Portfolio-CLI starting...");
      const userAgent = truncateText(navigator.userAgent, 80);
      const userIP = await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => data.ip);

      await addLine(`User Agent: ${userAgent}`);
      await addLine(`User IP Address: ${userIP}`);
      await addLine("Xmodem file system is available.");
      await addLine("Initializing Flash...");
      const totalBytes = 64016384;
      const bytesUsed = Math.floor(Math.random() * totalBytes);
      const bytesAvailable = totalBytes - bytesUsed;
      const fsckTime = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10 seconds

      await addLine(`flashfs[0]: 1 files, 0 directories`);
      await addLine(`flashfs[0]: 0 orphaned files, 0 orphaned directories`);
      await addLine(`flashfs[0]: Total bytes: ${totalBytes}`);
      await addLine(`flashfs[0]: Bytes used: ${bytesUsed}`);
      await addLine(`flashfs[0]: Bytes available: ${bytesAvailable}`);
      await addLine(`flashfs[0]: flashfs fsck took ${fsckTime} seconds.`);
      await addLine("...done Initializing Flash.");
      await addLine("");
      await addLine("Loading \"flash:brian_bell_portfolio-v1.0.bin\"...");
      await simulateLoading();
      await addLine("[OK]");
      await addLine("");
      const currentDate = new Date();
      const versionDate = currentDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '.');
      const compiledDate = currentDate.toLocaleString('en-US', { weekday: 'short', month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).replace(',', '');
      await addLine(`Portfolio OS, Version ${versionDate}, RELEASE SOFTWARE (P)`);
      await addLine(`Compiled ${compiledDate} by Brian Bell`);
      await addLine("");
      await addLine("Current Portfolio image: Primary");
      await addLine("Last update cause      : New Project: Netmiko AI");
      await addLine("");
      await addLine("Checking for Portfolio upgrade...");
      await addLine("Portfolio upgrade not required (Stage 2)");
      await addLine("POST: CPU MIC register Tests : Begin");
      await addLine("POST: CPU MIC register Tests : End, Status Passed");
      await addLine("POST: PortASIC Memory Tests : Begin");
      await addLine("POST: PortASIC Memory Tests : End, Status Passed");
      await addLine("All projects and skills loaded, ready for exploration");
      await addLine("");
      await addLine("|============================================================================|");
      await addLine("| Welcome to my Interactive Cisco IOS CLI Portfolio!                         |");
      await addLine("|----------------------------------------------------------------------------|");
      await addLine("| What you'll find here:                                                     |");
      await addLine("| - A bit about me, both professionally and personally                       |");
      await addLine("| - My skills, certifications, and the stuff I'm passionate about            |");
      await addLine("| - Projects I'm working on and things I'm learning                          |");
      await addLine("|                                                                            |");
      await addLine("| How to use this CLI:                                                       |");
      await addLine("| - Type '?' to see what commands you can use                                |");
      await addLine("| - Try out commands like 'show certifications' or 'show projects'           |");
      await addLine("| - Use Tab to auto-complete commands                                        |");
      await addLine("| - Shorthand commands are supported (e.g., 'sh' for 'show')                 |");
      await addLine("| - If you're feeling curious, type 'enable' for some extra info about me    |");
      await addLine("|   (you'll need a password for that one)                                    |");
      await addLine("|                                                                            |");
      await addLine("| Just a heads up: This is a work in progress, started on July 12, 2024.     |");
      await addLine("| I'm always updating it to make it closer to the real cisco ios.            |");
      await addLine("|                                                                            |");
      await addLine("|              !!!WARNING: NOT YET SUITABLE FOR MOBILE DEVICES!!!            |");
      await addLine("|============================================================================|");
      await addLine("");
      await addLine("");
      await addLine("");
      await addLine("");
      await addLine("");
      await addLine("");
      await addLine("");
      await addLine("");
      await addLine("");
      await addLine("Press RETURN to get started!");
      await addLine("");
      setStage('waitingForReturn');
    };
    bootSequence();

    window.addEventListener('keydown', handleGlobalKeyDown);

    return () => {
      window.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, [addLine, handleGlobalKeyDown, simulateLoading]);

  useEffect(() => {
    if (stage === 'ready' && !isProcessing) {
      if (isMobile) {
        setShowVirtualReturn(true);
      } else {
        inputRef.current?.focus();
      }
      resetIdleTimer();
    }
  }, [stage, isProcessing, output, resetIdleTimer]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const checkEnablePassword = useCallback(async (password) => {
    if (password === 'Jordan') {
      setMode('privileged');
      setAwaitingPassword(false);
      setAttemptCount(0);
      setIsAttemptingEnable(false);
      await addLine('');
    } else {
      const newCount = attemptCount + 1;
      setAttemptCount(newCount);
      if (newCount >= 3) {
        await addLine('% Bad secrets');
        await addLine('');
        setAwaitingPassword(false);
        setAttemptCount(0);
        setIsAttemptingEnable(false);
      } 
    }
    setInput('');
    inputRef.current?.focus();
  }, [setMode, addLine, setAwaitingPassword, attemptCount, setAttemptCount, setInput]);

  const getFullCommand = useCallback((shortCmd, context = '') => {
    let commands;
    if (context === 'show') {
      commands = [
        'about',
        'certifications',
        'contact',
        'education',
        'experience',
        'personal-note',
        'projects',
        'skills'
      ];
      if (mode === 'privileged') {
        commands.push('current-projects', 'future-certifications');
      }
    } else {
      commands = ['?', 'show', 'enable', 'disable', 'view-resume'];
    }
    
    // Check for exact match first (case-insensitive)
    const exactMatch = commands.find(cmd => cmd.toLowerCase() === shortCmd.toLowerCase());
    if (exactMatch) {
      return exactMatch;
    }
    
    const matchingCommands = commands.filter(cmd => cmd.toLowerCase().startsWith(shortCmd.toLowerCase()));
    if (matchingCommands.length === 1) {
      return matchingCommands[0];
    } else if (matchingCommands.length > 1) {
      return shortCmd; // Return the original input if there are multiple matches
    }
    return shortCmd; // Return the original input if there are no matches
  }, [mode]);

  const showCommandHelp = useCallback(async (args) => {
    const trimmedArgs = args.map(arg => arg.trim());
    if (trimmedArgs.length === 1 && trimmedArgs[0] === '?') {
      await addLine("Exec commands:");
      await addLine("  enable              Turn on privileged commands");
      await addLine("  show                Show information about brian");
      await addLine("  view-resume         View Brian's most up to date resume");
      if (mode === 'privileged') {
        await addLine("  disable             Turn off privileged commands");
      }
    } else if (trimmedArgs[0] === 'show' && (trimmedArgs[1] === '?' || trimmedArgs[1] === undefined)) {
      await addLine("  about                  Display information about Brian");
      await addLine("  certifications         Display professional certifications");
      await addLine("  contact                Display contact information");
      await addLine("  education              Display educational background");
      await addLine("  experience             Display work experience");
      await addLine("  personal-note          Display a personal note from Brian");
      await addLine("  projects               Display key projects");
      await addLine("  skills                 Display technical skills");
      if (mode === 'privileged') {
        await addLine("  current-projects       Display ongoing projects");
        await addLine("  future-certifications  Display certifications being pursued");
      }
    } else {
      await addLine("% Invalid input detected at '^' marker.");
    }
  }, [addLine, mode]);

  const handleIncompleteCommand = useCallback(async (command) => {
    await addLine(`% Incomplete command.`);
  }, [addLine]);

  const handleShowCommand = useCallback(async (args) => {
    if (args.length < 2) {
      await handleIncompleteCommand('show');
      return;
    }
    const showCommand = getFullCommand(args[1], 'show');
    switch(showCommand.toLowerCase()) {
      case 'about':
        await addLine("About Brian Bell:");
        await addLine("I'm a Network Engineer by day and an AI orchestrator by night.");
        await addLine("With 5+ years of experience in IT, I specialize in network");
        await addLine("engineering/administration.");
        await addLine("I have worked for notable government organizations such as the FBI,");
        await addLine("Air National Guard, and the Navy.");
        await addLine("I'm constantly expanding my skillset. Currently laser focused on AI and Automation");
        await addLine("with hopes of exploring the intersection of networking and AI.");
        await addLine("");
        await addLine("My goal is to leverage my experience in networking to innovate");
        await addLine("and augment the field.");
        await addLine("I believe in automating day-to-day tasks, even if it means");
        await addLine("potentially automating myself out of a job.(Check Out Netmiko AI in 'show projects')");
        await addLine("I truly feel like my purpose in life is to automate tasks that");
        await addLine("allow humans to focus on tasks that are better suited for our ");
        await addLine("cognitive strengths.");
        break;
      case 'projects':
        await addLine("Key Projects:");
        await addLine('1. <a href="https://github.com/brianbell-x/netmiko-ai" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">Netmiko AI</a>');
        await addLine("   - Developed an AI-powered assistant for network engineering tasks");
        await addLine("   - Integrates with Netmiko for device interactions and automation");
        await addLine("   - Includes token usage tracking and optimization");
        await addLine("   - Emphasizes security considerations and safe network changes");
        await addLine("   - (ALPHA) Automode for autonomous operations - Accomplishes a set of tasks no assistance.");
        await addLine("     Early signs show this it can research, troubleshoot, and solve issues autonomously.");
        await addLine("   - Technologies: Python, Claude 3.5 Sonnet, Netmiko");
        await addLine("");
        await addLine("2. Cisco IOS Portfolio");
        await addLine("   - Developed an interactive CLI emulating Cisco IOS");
        await addLine("   - Showcased network engineering skills and projects");
        await addLine("   - Implemented in React with custom styling");
        await addLine("   - React, Claude 3.5 Sonnet, GPT-4o");
        await addLine("");
        await addLine("3. Mixture of Experts (MoA) GUI with Streamlit");
        await addLine("   - Enabled visualization and control of MoA models");
        await addLine("   - Completed in June 2024");
        await addLine("   - Python, Streamlit, Claude 3.5 Sonnet");
        await addLine("");
        await addLine("4. Multiple Synthetic Data Generation Pipelines");
        await addLine("   - Created pipelines to generate synthetic data for training models");
        await addLine("   - Aimed to steer model behavior for specific tasks:");
        await addLine("     1. Network tool calling and usage");
        await addLine("        - Simulated network scenarios and tool interactions");
        await addLine("     2. Personalized life advice in my speaking style");
        await addLine("        - Generated advice mimicking my communication patterns");
        await addLine("     3. Lyric generation from personal content (seen below)");
        await addLine("        - Converted thoughts, journal entries, and vent sessions");
        await addLine("        - Produced song lyrics without explicit instructions");
        await addLine("   - Implemented self-refinement and human-in-the-loop features");
        await addLine("   - Included GUI for data visualization and interaction");
        await addLine("   - Latest pipeline completed: July 2024");
        await addLine("   - Technologies: Python, Claude 3.5 Sonnet");
        await addLine("");
        await addLine("5. LyricGen");
        await addLine("   - Fine-tuned GPT-3.5-turbo to write lyrics");
        await addLine("   - Utilized non-traditional sources like journal entries and venting sessions");
        await addLine("   - Completed in March 2024");
        await addLine("   - Python, GPT-4-turbo, OpenAI finetuning");
        break;
      case 'skills':
        await addLine("Technical Skills:");
        await addLine("- Network Engineering (Cisco)");
        await addLine("- Python Programming");
        await addLine("- Generative AI  (Prompt Engineering, Finetuning, Data Engineering,)");
        break;
      case 'experience':
        await addLine("Work Experience:");
        await addLine("1. Cyber Network Systems Operation Journeyman - Tennessee Air National Guard");
        await addLine("   Jan 2023 - Present");
        await addLine("   - Perform extensive physical and logical troubleshooting of NIPR and SIPR networks across layers 1-4");
        await addLine("   - Contribute to network expansion initiatives, enhancing infrastructure capabilities");
        await addLine("   - Manage and resolve network-related tickets, addressing connectivity and configuration challenges");
        await addLine("   - Diagnose network outages and manage carrier escalations for circuit-related problems");
        await addLine("   - Plan and execute scheduled network changes, maintaining detailed process documentation");
        await addLine("");
        await addLine("2. Azure Network Support Engineer - Microsoft Azure");
        await addLine("   Sept 2022 - Jan 2023");
        await addLine("   - Trained in Azure network services, including Load Balancer, DNS, and Virtual Networks");
        await addLine("   - Troubleshot and resolved customer network issues in cloud environments");
        await addLine("   - Participated in 24/7 on-call rotations for critical infrastructure support");
        await addLine("   - Maintained and updated technical documentation for Azure networking services");
        await addLine("");
        await addLine("3. Network Administrator - General Dynamics");
        await addLine("   Feb 2022 - Sept 2022");
        await addLine("   - Maintained over 50 FBI LANs and WANs, containing Cisco switches/routers and taclanes for encryption");
        await addLine("   - Provided remote guidance to on-site technicians for network troubleshooting and maintenance");
        await addLine("   - Used Riverbed and Network Node Manager to identify and resolve network issues");
        await addLine("");
        await addLine("4. Cyber Network Systems Operation Journeyman - Tennessee Air National Guard");
        await addLine("   Feb 2019 - Feb 2022");
        await addLine("   - Maintained NIPR and SIPR networks in compliance with federal guidelines for 500+ users");
        await addLine("   - Played a key role in a basewide tech refresh, transitioning from Avaya to Cisco technologies");
        await addLine("   - Deployed and managed cloud-controlled base-wide guest WiFi using Ubiquiti Unifi");
        break;
      case 'education':
        await addLine("Education:");
        await addLine("Western Governor's University - Expected Graduation: Fall 2025");
        await addLine("Bachelor of Science in Network Engineering and Security");
        await addLine("  Cisco Track");
        break;
      case 'contact':
        await addLine("Contact Information:");
        await addLine("Email: brianbell0505@gmail.com");
        await addLine('LinkedIn: <a href="https://linkedin.com/in/brian-bell-a5aa09167" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">linkedin.com/in/brian-bell-a5aa09167</a>', false, true);
        await addLine('X: <a href="https://x.com/brianbellx" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">@brianbellx</a>', false, true);
        break;
      case 'certifications':
        await addLine("Certifications:");
        await addLine('- <a href="https://cp.certmetrics.com/cisco/en/public/verify/credential/65fe8a6603694649821ebf92b8c64dbe" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">Cisco Certified Network Associate (CCNA)</a>', false, true);
        await addLine('- <a href="https://1drv.ms/b/c/adc075cb572eb754/ESJvVPGS8xhNmqz24AgtWzUBh5CbGR3y5uBEMBUE3yvAJg?e=T3urCt" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">CompTIA Security+</a>', false, true);
        await addLine('- <a href="https://1drv.ms/b/c/adc075cb572eb754/EWW--Q9fbmNGu3uZDe3nrzUBueRYR3lOQLHZbagTahxkiQ?e=foI0Il" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">CompTIA A+</a>', false, true);
        await addLine('- <a href="https://cs.lpi.org/caf/Xamman/certification/verify/LPI000593254/yl7rd5d6nz" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">LPI Linux Essentials</a>', false, true);
        await addLine('- <a href="https://1drv.ms/b/c/adc075cb572eb754/ER-st0ux-d9MmjsGNywgZe8BMaOE2LuF3SZ7JMDO5MQXsg?e=BAKNvl" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">ITIL Foundation</a>', false, true);
        break;
      case 'personal-note':
        await addLine("Personal Note:");
        await addLine("None of this would be possible without generative AI. I've become");
        await addLine("increasingly more productive as I spend time with these models.");
        await addLine("It has accelerated my development and learning more than I could");
        await addLine("ever imagine. It's gotten to a point to where I spend majority of my time");
        await addLine("building things to make me more productive.");
        break;
      case 'current-projects':
        if (mode === 'privileged') {
          await addLine("Private Projects:");
          await addLine("1. landbank+");
          await addLine("   - AI-powered software for Shelby County Landbank users");
          await addLine("   - Automates due diligence, code compliance checks, and zoning analysis");
          await addLine("   - Provides AI-generated improvement suggestions within zoning constraints");
          await addLine("   - Streamlines research tasks for efficient property management");
          await addLine("");
          await addLine("2. Blockchain/AI-Powered Polling System");
          await addLine("   - Combines Cardano blockchain for secure, transparent polling");
          await addLine("   - Integrates LLMs to explain complex laws to voters");
          await addLine("   - The LLMs assist users in making informed voting decisions");
          await addLine("   - Enhances polling integrity and voter understanding");
          await addLine("");
          await addLine("3. Cupid");
          await addLine("   - AI-powered life, relationship, and image consultant");
          await addLine("   - Replicates my speaking style for easy comprehension");
          await addLine("   - Continuously fine-tuned with approved TikTok data that allows it");
          await addLine("     to adapt to evolving trends for up-to-date guidance");
        }
        break;
      case 'future-certifications':
        if (mode === 'privileged') {
          await addLine("Future Certifications:");
          await addLine("- OCI Generative AI Professional (in-progress-personal)");
          await addLine("- Cisco CyberOps Associate (in-progress-wgu.edu)");
          await addLine("- Cisco DevNet Associate");
          await addLine("- Infiniband Professional");
        } else {
          await addLine("Access denied. Please enter privileged exec mode.");
        }
        break;
      default:
        await addLine(`% Unrecognized command`);
    }
  }, [addLine, mode, getFullCommand, handleIncompleteCommand]);

  const processCommand = useCallback(async (cmd) => {
    setIsProcessing(true);
    await addLine(`BRIAN-BELL${mode === 'privileged' ? '#' : '>'}${cmd}`);
    
    if (cmd.trim() === '') {
      setIsProcessing(false);
      return;
    }
    
    const args = cmd.toLowerCase().split(' ');
    const fullCommand = getFullCommand(args[0]);
    
    const openResumeLink = async () => {
      const resumeLink = 'https://1drv.ms/w/s!AlS3LlfLdcCtdnG8VdEOlP3Dvm8?e=OEuS5i';
      window.open(resumeLink, '_blank');
      await addLine('Opening resume link in a new tab. Please check your browser.');
    };

    switch(fullCommand) {
      case '?':
        await showCommandHelp(args);
        break;
      case 'show':
        if (args.length === 1) {
          await handleIncompleteCommand(cmd);
        } else if (args[1] === '?') {
          await showCommandHelp(['show', '?']);
        } else {
          await handleShowCommand(args);
        }
        break;
      case 'enable':
        if (mode === 'user') {
          setAwaitingPassword(true);
          setAttemptCount(0);
          setIsAttemptingEnable(true);
        }
        break;
      case 'disable':
        if (mode === 'privileged') {
          setMode('user');
        } 
        break;
      case 'view-resume':
        await openResumeLink();
        break;
      default:
        if (fullCommand === args[0]) {
          await addLine(`% Unrecognized command`);
        } else {
          await addLine("% Ambiguous command.  Possible completions:");
          // Here you would list possible completions
        }
    }
    
    setIsProcessing(false);
  }, [addLine, mode, getFullCommand, setIsProcessing, showCommandHelp, handleShowCommand, handleIncompleteCommand, setAwaitingPassword, setMode]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter' && !isProcessing && stage === 'ready') {
      e.preventDefault();
      if (awaitingPassword) {
        checkEnablePassword(input);
      } else {
        processCommand(input);
        setInput('');
        if (input.trim() !== '') {
          setCommandHistory(prev => [...prev, input]);
        }
        setHistoryIndex(-1);
      }
      resetIdleTimer();
    }
  }, [isProcessing, stage, awaitingPassword, input, checkEnablePassword, processCommand, setInput, setCommandHistory, setHistoryIndex, resetIdleTimer]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (historyIndex < commandHistory.length - 1) {
        setHistoryIndex(prev => prev + 1);
        setInput(commandHistory[commandHistory.length - 1 - historyIndex - 1]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > -1) {
        setHistoryIndex(prev => prev - 1);
        setInput(historyIndex === 0 ? '' : commandHistory[commandHistory.length - 1 - historyIndex + 1]);
      }
    } else if (e.key === 'Tab') {
      e.preventDefault();
      const fullCommand = getFullCommand(input.toLowerCase());
      if (fullCommand !== input.toLowerCase()) {
        setInput(fullCommand);
      }
    }
  };

  const handleVirtualReturn = () => {
    setShowVirtualReturn(false);
    setStage('ready');
    inputRef.current?.focus();
  };

  const handleContainerClick = useCallback(() => {
    if (isMobile && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const renderOutput = () => {
    return output.map((line, index) => (
      <div key={index} dangerouslySetInnerHTML={{ __html: line }} />
    ));
  };

  const renderInput = () => {
    if (stage !== 'ready' || isLoggedOut) return null;

    return (
      <div className="flex items-center">
        {!awaitingPassword && (
          <span>BRIAN-BELL{mode === 'privileged' ? '#' : '>'}</span>
        )}
        {awaitingPassword && <span>Password: </span>}
        <input
          ref={inputRef}
          type={awaitingPassword ? "password" : "text"}
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
          className="bg-black text-white focus:outline-none flex-grow ml-1"
          spellCheck="false"
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          disabled={isProcessing}
        />
      </div>
    );
  };

  return (
    <div className="h-screen flex">
      {/* Left half of the screen - CLI interface */}
      <div className="w-1/2 flex flex-col">
        <div 
          ref={containerRef}
          className="bg-black text-white p-4 font-['Courier',_monospace] h-full flex flex-col text-sm text-left overflow-hidden"
          onClick={handleContainerClick}
        >
          <div ref={outputRef} className="flex-grow whitespace-pre-wrap overflow-auto text-left custom-scrollbar">
            {renderOutput()}
            {renderInput()}
          </div>
        </div>
        {showVirtualReturn && (
          <button
            onClick={handleVirtualReturn}
            className="bg-blue-500 text-white p-4 mt-4 rounded text-xl w-full text-center"
          >
            Press RETURN to continue
          </button>
        )}
      </div>

      {/* Right half of the screen */}
      <div className="w-1/2 bg-black p-4 text-white">
        {awaitingPassword && (
          <div className="text-sm">
            <p className="mt-2"><strong>Hint:</strong> The enable password is my middle name.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrianBellCLI;