import React from 'react';
import './App.css';
import BrianBellCLI from './components/CiscoIOSCLI';
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <div className="App">
      <BrianBellCLI />
      <Analytics />
    </div>
  );
}

export default App;